import type { SiteConfig } from "../sites.ts";

export const dataAssetConfig: SiteConfig = {
  company: {
    name: "DataAsset.Store",
    tagline: "Unlocking Data Potential",
    description: "Innovative data asset management and analytics solutions",
    logo: {
      svg: `<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-[var(--color-primary)]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4" />
      </svg>`
    }
  },
  theme: {
    primary: '#7c3aed',    // Purple
    secondary: '#6d28d9',
    accent: '#8b5cf6',
    background: '#f9fafb',
    text: '#111827',
  },
  footerLinks: [
    { label: "About", href: "/about" },
    { label: "Privacy", href: "/privacy" },
    { label: "Terms", href: "/terms" },
    { label: "Contact", href: "#contact" }
  ],
  aboutPage: {
    title: "About Data Asset",
    subtitle: "Revolutionizing Data Management",
    mission: {
      title: "Our Mission",
      content: "To help organizations unlock the full potential of their data assets."
    },
    team: [
      {
        name: "Emma Thompson",
        role: "CEO",
        bio: "Pioneer in data management solutions."
      },
      {
        name: "James Lee",
        role: "Head of Analytics",
        bio: "Expert in big data and analytics."
      },
      {
        name: "Anna Kowalski",
        role: "Chief Data Scientist",
        bio: "PhD in Data Science, former Google researcher."
      }
    ],
    values: [
      {
        title: "Innovation",
        description: "Pushing the boundaries of data management."
      },
      {
        title: "Security",
        description: "Ensuring the highest level of data protection."
      },
      {
        title: "Efficiency",
        description: "Optimizing data processes for better results."
      }
    ]
  },
  portfolioPage: {
    title: "Solutions Portfolio",
    subtitle: "Comprehensive Data Management Solutions",
    companies: [
      {
        name: "DataFlow",
        description: "Real-time data processing",
        status: "Enterprise",
        year: 2020
      },
      {
        name: "SecureVault",
        description: "Data security platform",
        status: "Enterprise",
        year: 2019
      },
      {
        name: "AnalyticsPro",
        description: "Advanced analytics suite",
        status: "Enterprise",
        year: 2021
      }
    ],
    investmentCriteria: [
      {
        title: "Data Focus",
        description: "Solutions centered on data management"
      },
      {
        title: "Security",
        description: "Strong security foundations"
      },
      {
        title: "Scalability",
        description: "Enterprise-grade architecture"
      }
    ]
  },
  technologyPage: {
    title: "Our Technology Stack",
    subtitle: "Building the Future with Advanced Technology",
    markdown: `
# Our Technology

## Core Capabilities

Our technology stack enables powerful solutions across multiple domains:

### Cloud Infrastructure
- Scalable architecture
- Multi-region deployment 
- High availability systems

### AI and Machine Learning
- Advanced NLP models
- Computer vision systems
- Predictive analytics

### Security
- End-to-end encryption
- Regular security audits
- Compliance with industry standards

## Innovation Focus

We're constantly pushing the boundaries of what's possible with:

- Large Language Models
- Agent-based AI Systems
- Edge Computing
- Quantum Computing Research

Contact us to learn more about our technology solutions.
`
  },
  solutionsPage: {
    title: "Our Solutions",
    subtitle: "Comprehensive AI and Data Solutions",
    markdown: `
# Enterprise Solutions

## AI Integration
- Custom LLM development
- AI agent deployment
- Workflow automation

## Data Solutions  
- Data pipeline development
- Analytics platforms
- Business intelligence

## Security Solutions
- AI-powered security
- Compliance automation
- Threat detection

Contact us to discuss your specific requirements.
`
  },
  homePage: {
    hero: {
      title: "Master Your Data Assets",
      highlight: "Unlock True Value",
      description: "Comprehensive solutions for modern data management challenges."
    },
    config: {
      showPromoVideo: true,
      showContactForm: true
    }
  }
};
