import type { SiteConfig } from "../sites.ts";

export const chainTokenomicsConfig: SiteConfig = {
  company: {
    name: "ChainTokenomics",
    tagline: "Revolutionizing Startup Funding with DeFi",
    description: "Blockchain consultancy specializing in Ethereum and innovative tokenization solutions",
    logo: {
      svg: `<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-[var(--color-primary)]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
      </svg>`
    }
  },
  theme: {
    primary: '#22c55e',    // Green
    secondary: '#16a34a',
    accent: '#4ade80',
    background: '#0f172a',
    text: '#f8fafc',
  },
  footerLinks: [
    { label: "About", href: "/about" },
    { label: "Privacy", href: "/privacy" },
    { label: "Terms", href: "/terms" },
    { label: "Contact", href: "#contact" }
  ],
  aboutPage: {
    title: "About ChainTokenomics",
    subtitle: "Pioneering Deferred Revenue Tokens",
    mission: {
      title: "Our Mission",
      content: "To revolutionize startup funding through innovative blockchain solutions and DeFi mechanisms."
    },
    team: [
      {
        name: "Alex Chen",
        role: "Founder & CEO",
        bio: "Ethereum core contributor, DeFi protocol architect"
      },
      {
        name: "Sarah Williams",
        role: "Head of Tokenomics",
        bio: "Former Goldman Sachs, crypto economics specialist"
      },
      {
        name: "Marcus Kumar",
        role: "Lead Smart Contract Developer",
        bio: "Solidity expert, audited over 50 DeFi protocols"
      }
    ],
    values: [
      {
        title: "Innovation",
        description: "Creating new paradigms in blockchain finance"
      },
      {
        title: "Security",
        description: "Ensuring robust and audited smart contracts"
      },
      {
        title: "Transparency",
        description: "Building trust through open-source development"
      }
    ]
  },
  portfolioPage: {
    title: "Solutions & Products",
    subtitle: "Revolutionary DeFi Tools",
    companies: [
      {
        name: "Deferred Revenue Tokens",
        description: "Pre-purchase product tokenization platform",
        status: "Live",
        year: 2024
      },
      {
        name: "ETH Consulting",
        description: "Enterprise blockchain solutions",
        status: "Active",
        year: 2023
      },
      {
        name: "Smart Contract Auditing",
        description: "Security and optimization services",
        status: "Active",
        year: 2023
      }
    ],
    investmentCriteria: [
      {
        title: "Innovation",
        description: "Novel approaches to blockchain solutions"
      },
      {
        title: "Security",
        description: "Battle-tested smart contract architecture"
      },
      {
        title: "Scalability",
        description: "Layer 2 and scaling solution ready"
      }
    ]
  },
  homePage: {
    hero: {
      title: "Future of Startup Funding",
      highlight: "Deferred Revenue Tokens",
      description: "Revolutionizing startup funding through tokenized pre-purchases. Secure, transparent, and efficient."
    },
    config: {
      showPromoVideo: true,
      showContactForm: true
    }
  }
};
